import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./style.scss";
import roundLogo from "../Assets/IMG/round-red-logo.svg";
import arrow from "./arrow.svg";
import projectData from "../Projects/projects.js";
//import projectData from "../Projects/projects-experiment.js";
import SideInfo from "../SideInfo";
import SideMobile from "../SideMobile";
import SideImage from "../SideImage";
import StackedExample from "../StackedExample";
import uniqueId from "react-html-id";
import config from "../App/mixpanel-events";
import mixpanel from "mixpanel-browser";

class FullScreenView extends Component {
  constructor(props) {
    super(props);

    uniqueId.enableUniqueIds(this);

    this.mainUI = null;
    this.navUI = null;

    this.setMainUI = e => {
      this.mainUI = e;
    };

    this.setNavUI = e => {
      this.navUI = e;
    };

    this.previousProject = this.previousProject.bind(this);
    this.nextProject = this.nextProject.bind(this);
    this.handleKey = this.handleKey.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.state = {
      jsonProjects: projectData,
      currentProjectUrl:
        this.props.history.location.pathname === "/project" ||
        this.props.history.location.pathname === "/project/"
          ? "siriusxm"
          : this.props.history.location.pathname.slice(9)
    };

    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.focusUI();
    window.scroll(0, 0);
    mixpanel.track(config.mixpanelEvents.Projects);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  currentProject = () => {
    let project = this.state.jsonProjects.projects.find(element => {
      return element.url === this.state.currentProjectUrl;
    });
    return project;
  };

  currentProjectIndex = () => {
    let index = this.state.jsonProjects.projects.findIndex(element => {
      return element.url === this.state.currentProjectUrl;
    });
    return index;
  };

  previousProject = () => {
    let previousProjectIndex = index => {
      if (index === 0) {
        return this.state.jsonProjects.projects.length - 1;
      }

      return index - 1;
    };

    this.setState({
      currentProjectUrl: this.state.jsonProjects.projects[
        previousProjectIndex(this.currentProjectIndex())
      ].url
    });

    this.props.history.push(
      `/project/${
        this.state.jsonProjects.projects[
          previousProjectIndex(this.currentProjectIndex())
        ].url
      }`
    );

    window.scroll(0, 0);
  };

  nextProject = () => {
    let nextProjectIndex = index => {
      if (index === this.state.jsonProjects.projects.length - 1) {
        return 0;
      }

      return index + 1;
    };

    this.setState({
      currentProjectUrl: this.state.jsonProjects.projects[
        nextProjectIndex(this.currentProjectIndex())
      ].url
    });

    this.props.history.push(
      `/project/${
        this.state.jsonProjects.projects[
          nextProjectIndex(this.currentProjectIndex())
        ].url
      }`
    );

    window.scroll(0, 0);
  };

  handleKey = e => {
    if (e.key === "ArrowRight") {
      this.nextProject();
    }

    if (e.key === "ArrowLeft") {
      this.previousProject();
    }
  };

  handleScroll = e => {
    if (this.navUI) {
      window.scrollY > 0
        ? (this.navUI.className = "logo logo-elevation")
        : (this.navUI.className = "logo");
    }
  };

  focusUI = () => {
    if (this.mainUI) this.mainUI.focus();
  };

  sideBySideLayout = content => {
    return (
      <div className="card mobile-card" key={this.nextUniqueId()}>
        <SideInfo Project={this.currentProject()} Content={content} />
        <SideImage Project={this.currentProject()} Content={content} />
      </div>
    );
  };

  sideBySideMobileLayout = content => {
    return (
      <div className="card mobile-card" key={this.nextUniqueId()}>
        <SideInfo Project={this.currentProject()} Content={content} />
        <SideMobile Project={this.currentProject()} Content={content} />
      </div>
    );
  };

  stackedLayout = content => {
    return (
      <div className="card desktop-card" key={this.nextUniqueId()}>
        <StackedExample Project={this.currentProject()} Content={content} />
      </div>
    );
  };

  renderCards = () => {
    const layout = this.currentProject().sections.map(section => {
      if (section.sidebyside === "true") {
        if (section.mobile === "true") {
          return this.sideBySideMobileLayout(section);
        }
        return this.sideBySideLayout(section);
      }
      return this.stackedLayout(section);
    });

    return layout;
  };

  render() {
    return (
      <div
        className="appFull"
        style={{ outline: "none" }}
        onKeyDown={this.handleKey}
        tabIndex={0}
        ref={this.setMainUI}
      >
        <div className="logo" ref={this.setNavUI}>
          <NavLink exact to="/projects">
            <img src={roundLogo} alt="Logo" className="round-logo-image" />
          </NavLink>
        </div>

        <div className="pageFull" id="main-page">
          <div className="cards">
            <div className="button left" onClick={this.previousProject}>
              <div className="circle-container">
                <div className="circle">
                  <img src={arrow} alt="" />
                </div>
              </div>
            </div>
            {this.renderCards()}
            <div className="button right" onClick={this.nextProject}>
              <div className="circle-container">
                <div className="circle">
                  <img src={arrow} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FullScreenView;
