import React, { Component } from "react";
import "./NavPanel.scss";
import { NavLink } from "react-router-dom";
import logo from "../Assets/IMG/nycodeco-large.svg";

class NavPanel extends Component {
  render() {
    return (
      <div className="panel-left">
        <div className="NavPanel">
          <img src={logo} alt="Logo" className="logo-image" />
          <ul className="NavList">
            <li>
              <NavLink exact to="/" activeClassName="active-nav">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/services" activeClassName="active-nav">
                Services
              </NavLink>
            </li>
            <li>
              <NavLink to="/projects" activeClassName="active-nav">
                Projects
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default NavPanel;
