import React, { Component } from "react";
import "./style.css";

class StackedExample extends Component {
  render() {
    let imgUrl = `/${process.env.PUBLIC_URL}img/projects/${
      this.props.Project.url
    }/${this.props.Content.image}`;

    return (
      <div className="section-top">
        <a href={imgUrl}>
          <img
            src={imgUrl}
            alt={this.props.Content.title}
            className="desktopImg"
          />
        </a>
      </div>
    );
  }
}

export default StackedExample;
