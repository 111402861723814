import React from "react";
import PH from "./ph.png";
import "./style.scss";

const ProductHunt = props => {
  return (
    <div className="c-product-hunt">
      <a href={props.link}>
        <div className="c-product-hunt__image-container">
          <img src={PH} alt="product hunt logo" />
        </div>
        <span>{props.children}</span>
      </a>
    </div>
  );
};

export default ProductHunt;
