import React, { Component } from "react";
import "./style.scss";
import AssessmentButton from "../AssessmentButton";
import AssessmentDate from "../AssessmentDate";
import config from "../App/mixpanel-events";
import mixpanel from "mixpanel-browser";

class ServicesContent extends Component {
  constructor(props) {
    super(props);

    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
  }

  componentDidMount() {
    mixpanel.track(config.mixpanelEvents.Services);
  }

  render() {
    return (
      <div className="panel-right">
        <div className="ServicesContent">
          <h1>Our services.</h1>
          <p className="services-intro">
            <ol>
              <li>- Product/UX/UI design</li>
              <li>- Design systems</li>
              <li>- Design assessments</li>
              <li>- User testing & research</li>
              <li>- UI & conversion optimization</li>
              <li>- User retention design</li>
            </ol>
          </p>
          <div className="services-call-button">
            <AssessmentButton />
            <small>
              15 minute intro call available on <AssessmentDate />.
            </small>
          </div>
        </div>
      </div>
    );
  }
}

export default ServicesContent;
