import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.scss";
import SplitView from "../SplitView";
import FullScreenView from "../FullScreenView";

class App extends Component {
  render() {
    return (
      // Router docs
      // https://reacttraining.com/react-router/web/example/basic
      <Router>
        <div id="main-app">
          <Route exact path="/" component={SplitView} />
          <Route path="/services" component={SplitView} />
          <Route exact path="/projects" component={SplitView} />
          <Route path="/project/" component={FullScreenView} />
        </div>
      </Router>
    );
  }
}

export default App;
