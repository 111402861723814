import React, { Component } from "react";
import ReactModal from "react-modal";
import "./style.scss";
import AssessmentDate from "../AssessmentDate";
import roundLogo from "./nycc-round.svg";
import carrot from "./carrot.svg";
import "whatwg-fetch";
import { API } from "aws-amplify";

// This component uses react-modal, the documentation is here:  https://reactcommunity.org/react-modal/

class AssessmentButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      modalIsOpen: false,
      emailValid: false,
      emailLabelStyle: "email-label",
      emailInvalidLabel: "invalid-label-hidden"
    };

    //setup form
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    //setup modal
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  //display the input that is typed
  handleChange(event) {
    this.setState({ value: event.target.value });
    this.setState({ emailValid: event.target.validity.valid });
  }

  //handle the submit of the form
  handleSubmit(event) {
    event.preventDefault();

    if (this.state.emailValid) {
      this.setState({
        emailLabelStyle: "email-label",
        emailInvalidLabel: "invalid-label-hidden",
        modalIsOpen: false
      });

      let lead = {
        email: this.state.value
      };

      return API.post("nycodeco", "/send-lead", {
        body: lead
      });
    } else {
      this.setState({
        emailLabelStyle: "email-label-hidden",
        emailInvalidLabel: "invalid-label"
      });
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    //this.refs.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <div>
        <button className="assessment-button" onClick={this.openModal}>
          Schedule Call
        </button>
        <ReactModal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          className="modal"
          overlayClassName="overlay"
          contentLabel="I need your email address to confirm an assessment."
        >
          <img src={roundLogo} alt="Logo" className="modal-logo" />
          <p className="modal-headline">
            Our next available time to chat is on <AssessmentDate />.
          </p>
          <p className="modal-body">
            Just give us a good email address and we will be in touch to see
            what works for you.
          </p>
          <form onSubmit={this.handleSubmit} noValidate="true">
            <div className={this.state.emailLabelStyle}>Email</div>
            <div className={this.state.emailInvalidLabel}>
              Hmmm, that email does not look right.
            </div>
            <input
              type="email"
              title="The domain portion of the email address is invalid (the portion after the @)."
              pattern="^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$"
              name="email"
              id="email"
              ref="email"
              value={this.state.value}
              onChange={this.handleChange}
              className="email-input"
              required
            />
            <div className="fine-print">
              We will only use this email address to contact you about working
              with us.
            </div>
            <button type="submit" className="modal-submit">
              Send Email
            </button>
          </form>
          <button onClick={this.closeModal} className="modal-close">
            <img src={carrot} alt="" className="carrot" />
            BACK
          </button>
        </ReactModal>
      </div>
    );
  }
}

export default AssessmentButton;
