const projectData = {
  projects: [
    {
      title: "InVision",
      url: "invision",
      thumbnail: "thumb.png",
      sections: [
        {
          title: "InVision",
          subtitle:
            "Product lead for multiple features during an incredible period of growth.",
          year: "2015 - 2016",
          image: "inspect.png",
          sidebyside: "true",
          mobile: "false",
          description: [
            {
              text:
                "InVision is one of the leading tools for digital designers. I helped bring three new major features to market during one of the most significant periods of growth for the company."
            },
            {
              text: "Features I worked on:",
              bullets: [
                "Inspect",
                "Mobile",
                "User Testing",
                "Overlays",
                "Motion"
              ]
            }
          ]
        },
        {
          title: "InVision Inspect",
          subtitle: "A design handoff tool",
          year: "2016",
          sidebyside: "true",
          mobile: "true",
          proto: "false",
          video: "true",
          videoSource: "insight.mp4",
          productHunt: {
            text: "2016.",
            link: "https://www.producthunt.com/posts/inspect-by-invision"
          },
          description: [
            {
              text:
                "Originally named “Insight” as you can see in the product video here."
            },
            {
              text:
                "Conceptualized in the summer of 2015, and launched in Beta form in early 2016."
            },
            {
              text:
                "This was a deeply technical product that required understanding of developer workflow, designer workflow, as well as the complexities involved in parsing design files of various types (sketch, photoshop) and reconstructing them in the browser."
            }
          ]
        },
        {
          image: "inspect.png",
          sidebyside: "false",
          mobile: "false"
        },
        {
          title: "InVision Mobile & User Testing",
          year: "2015",
          sidebyside: "true",
          mobile: "true",
          proto: "false",
          video: "true",
          videoSource: "user-testing.mp4",
          productHunt: {
            text: "2015.",
            link: "https://www.producthunt.com/posts/invision-mobile-testing"
          },
          description: [
            {
              text:
                "I launched the first mobile app experience at InVision which also included support for Apple Watch and the ability to perform mobile User Tests."
            },
            {
              text:
                "The app would present a prototype to the user, and it would take three recordings to aid in the user test review:",
              bullets: [
                "Screen recording of every action the user took",
                "Video of the user from the front camera of the phone",
                "Audio of the user from the microphone"
              ]
            }
          ]
        },
        {
          title: "InVision Overlays",
          subtitle: "A prototyping feature",
          year: "2015",
          sidebyside: "true",
          mobile: "true",
          proto: "false",
          video: "true",
          videoSource: "overlays.mp4",
          productHunt: {
            text: "2015.",
            link: "https://www.producthunt.com/posts/invision-overlays"
          },
          description: [
            {
              text:
                "This was one of the top feature requests from our customers, and one of the most used interactive features after the launch."
            }
          ]
        },
        {
          title: "InVision Motion",
          subtitle: "A prototyping feature",
          year: "2015",
          sidebyside: "true",
          mobile: "true",
          proto: "false",
          video: "true",
          videoSource: "motion.mp4",
          description: [
            {
              text:
                "Motion was a set of features intended to help design microinteractions and generate code for those interactions. I was the product lead and helped get the initial proof of concepts complete until I later handed the product off to another leader as the company grew."
            }
          ]
        },
        {
          image: "motion.png",
          sidebyside: "false",
          mobile: "false"
        }
      ]
    },
    {
      title: "SiriusXM Video",
      url: "siriusxm",
      thumbnail: "thumb.png",
      sections: [
        {
          title: "SiriusXM Video",
          subtitle: "Designed video experience proof of concept.",
          year: "2017",
          image: "siriusxm.png",
          sidebyside: "true",
          mobile: "true",
          testimonial: [
            {
              quote: [
                "Christopher spans across multiple disciplines of Product and Design to solve challenges holistically.",
                "He 'owned' our problem taking much of the burden off of my shoulders by working hard to understand the business goals and document it so that I didn't have to send detailed lists and requirements.",
                "The design work was accurate and complete which resulted in efficient development."
              ],
              image: "jt-img.png",
              name: "JT Katzman",
              company: "Product Manager - SiriusXM",
              link: "https://www.linkedin.com/in/jtkatzman/"
            }
          ],
          description: [
            {
              text:
                "SiriusXM needed to design and build a new mobile app that would introduce video into the experience. The design challenge was how to incorporate video into an audio experience where the same content could be consumed as audio or video."
            },
            {
              text:
                "I designed this experience which was developed and ultimately used to inform the final product that you see in the App Store today."
            }
          ]
        },
        {
          image: "browse.png",
          sidebyside: "false",
          mobile: "false"
        },
        {
          image: "profile.png",
          sidebyside: "false",
          mobile: "false"
        },
        {
          image: "playback.png",
          sidebyside: "false",
          mobile: "false"
        },
        {
          image: "audio.png",
          sidebyside: "false",
          mobile: "false"
        }
      ]
    },
    {
      title: "Music Discovery App",
      url: "reverb-discover",
      thumbnail: "thumb.png",
      sections: [
        {
          title: "Music Discovery App",
          subtitle: "Product lead for the ReverbNation music discovery app.",
          year: "2015",
          image: "discover.png",
          sidebyside: "true",
          mobile: "true",
          productHunt: {
            text: "2015.",
            link: "https://www.producthunt.com/posts/reverbnation-discover"
          },
          genericInfo: {
            source: "Forbes",
            date: "2015",
            link:
              "https://www.forbes.com/sites/hughmcintyre/2015/07/28/reverbnation-launches-echomusic-a-new-app-for-discovering-only-the-best-of-new-acts/",
            image: "forbes.png"
          }
        },
        {
          image: "screens.png",
          sidebyside: "false",
          mobile: "false"
        },
        {
          title: "Prototype",
          image: "discover.png",
          sidebyside: "true",
          mobile: "true",
          proto: "true",
          protoSource: "https://invis.io/B8PE9UPKVFY",
          protoAltImage: "invision_icon_pink.png",
          description: [
            {
              text:
                "This prototype is the output of a design sprint, and tested with real users to get feedback.",
              bullets: [
                "We ran three Design Sprints following the exact Google model for a sprint.",
                "We tested the output prototype of each sprint with actual users using a remote testing tool."
              ]
            }
          ]
        }
      ]
    },
    {
      title: "VoiceThread",
      url: "voicethread",
      thumbnail: "thumb.png",
      sections: [
        {
          title: "VoiceThread",
          subtitle: "Designed a suite of digital education tools.",
          year: "2018",
          image: "section-1.png",
          sidebyside: "true",
          mobile: "true",
          testimonial: [
            {
              quote: [
                "There's a clear difference between pre-Christopher and post-Christopher when it comes to design, and design of the user experience. It just it feels right."
              ],
              image: "steve-muth.png",
              name: "Steve Muth",
              company: "President - VoiceThread",
              link: "https://www.linkedin.com/in/stevemuth/"
            }
          ],
          description: [
            {
              text:
                "VoiceThread has been operating for many years without a designer. They have a complex product that has pivoted from a collaborative media experience into a way for educators to interact with and teach their students. The lack of consistent design throughout the product was presenting many challenges to the business as well as it’s customers."
            }
          ]
        },
        {
          image: "section-2.png",
          sidebyside: "false",
          mobile: "false"
        },
        {
          title: "Objectives",
          image: "section-2a.png",
          sidebyside: "true",
          mobile: "true",
          testimonial: [
            {
              quote: [
                "[Christopher has] religion on defining the problem and getting the fundamental objectives right."
              ],
              image: "steve-muth.png",
              name: "Steve Muth",
              company: "President - VoiceThread",
              link: "https://www.linkedin.com/in/stevemuth/"
            }
          ],
          description: [
            {
              text:
                "The client had three main objectives for its engagement with me:",
              bullets: [
                "Improve the overall User Experience of the product.",
                "Design and develop a system of components that their development team could use to assemble together experiences.",
                "Design a new suite of digital education tools that would work with their existing product."
              ]
            }
          ]
        },
        {
          image: "section-3a.png",
          sidebyside: "false",
          mobile: "false"
        },
        {
          title: "Constraints",
          image: "section-3.png",
          sidebyside: "true",
          mobile: "true",
          description: [
            {
              text:
                "Because of the nature of this clients customers, VoiceThread only had a few opportunities per year to release updates and new features.",
              bullets: [
                "Each release had to be highly coordinated with customers months in advance.",
                "The product had to meet a minimum of Level AA WCGAG compliance.",
                "The product had to work in a variety of standard large and small size screens.",
                "Embedding of the product was important and many non-standard size screens needed to be accounted for.",
                "End users ranged widely in their ability to use and comprehend software (OECD level 1 - level 3)."
              ]
            }
          ]
        },
        {
          image: "section-4.png",
          sidebyside: "false",
          mobile: "false"
        },
        {
          title: "Approach",
          image: "section-5.png",
          sidebyside: "true",
          mobile: "false",
          testimonial: [
            {
              quote: [
                "Christopher exposed our company to this idea that user experience has structure to it, and that if you use that structure, you really can make the user experience so much better."
              ],
              image: "steve-muth.png",
              name: "Steve Muth",
              company: "President - VoiceThread",
              link: "https://www.linkedin.com/in/stevemuth/"
            }
          ],
          description: [
            {
              text: "",
              bullets: [
                "Understand the client's customers, their demographics, their technical proficiency.",
                "Build or choose a design system that would be usable and familiar to the end users.",
                "Design UI’s and build prototypes of the new features with the design system.",
                "Develop a library of components in HTML & CSS that developers could pick and choose from."
              ]
            }
          ]
        },
        {
          title: "Conclusion",
          image: "section-6.png",
          sidebyside: "true",
          mobile: "false",
          testimonial: [
            {
              quote: [
                "Christopher forces you to slow down and think deeper about whatever problem has been put forward. The solution is not nearly as important as defining the problem, and so forcing a better definintion of the problems is a huge benefit."
              ],
              image: "steve-muth.png",
              name: "Steve Muth",
              company: "President - VoiceThread",
              link: "https://www.linkedin.com/in/stevemuth/"
            }
          ],
          description: [
            {
              text:
                "VoiceThread continues to be a client today, and we are solving new problems and improving the product each week."
            }
          ]
        }
      ]
    },
    {
      title: "GetGlue",
      url: "getglue",
      thumbnail: "thumb.png",
      sections: [
        {
          title: "GetGlue",
          subtitle: "Director of product.",
          year: "2011 - 2012",
          image: "section-1.png",
          sidebyside: "true",
          mobile: "false",
          testimonial: [
            {
              quote: [
                "Christopher is someone who really \"gets\" product. He led the product team at GetGlue during the company's biggest growth period. This is not a coincidence. Christopher has the intelligence and understanding to know what features and changes will deliver the best experience for users and the most impact to the company's metrics. He also was able to deal with internal challenges of conflicting opinions and priorities while always treating everyone from executives to the newest junior engineers with with respect and appreciation."
              ],
              image: "andy-roth.png",
              name: "Andy Roth",
              company: "VP of Product Quality - GetGlue",
              link: "https://www.linkedin.com/in/andyroth/"
            }
          ],
          description: [
            {
              text:
                "GetGlue was a Union Square Ventures (Twitter, Etsy, Foursquare, Tumblr) backed social networking product for entertainment. It helped fuel the hype around check-ins as a form of sharing with your friends."
            },
            {
              text:
                "I helped drive the growth of the product by designing and executing excellent user experiences."
            }
          ]
        },
        {
          title: "Check-Ins",
          subtitle: "The core user action of GetGlue.",
          year: "2011",
          image: "section-2.png",
          sidebyside: "true",
          mobile: "false",
          description: [
            {
              text:
                "The top priority for GetGlue was increasing the velocity of user growth, but the path to achieving this objective was unclear."
            },
            {
              text:
                "There was one known fact that users themselves were the largest source of growth for the product by spreading it to their friends."
            },
            {
              text:
                "After careful quantitative analysis of the various paths that drove the most sign-ups, user interviews about their behavior, and a user experience analysis of the product I was able to come up with a hypothesis for driving more growth."
            },
            {
              text:
                "The hypothesis proved true and we increased engagement 10X and signups doubled from 1k a day to 2k a day."
            }
          ]
        },
        {
          image: "section-3.png",
          sidebyside: "false",
          mobile: "false"
        },
        {
          image: "section-4.png",
          sidebyside: "false",
          mobile: "false"
        },
        {
          image: "section-5.png",
          sidebyside: "false",
          mobile: "false"
        },
        {
          title: "Press Coverage",
          year: "2011 - 2012",
          image: "animation.gif",
          sidebyside: "true",
          mobile: "true",
          testimonial: [
            {
              quote: [
                "Check in to Entertainment & Foursquare Simultaneously on GetGlue"
              ],
              image: "mashable.jpg",
              name: "Mashable",
              company: "March 29, 2011",
              link:
                "https://mashable.com/2011/03/29/getglue-foursquare/#.cQZ5X4nhqqK"
            },
            {
              quote: ["GetGlue Passes 1M Users; 100M Data Points"],
              image: "TechCrunch_2013.svg",
              name: "TechCrunch",
              company: "April 13, 2011",
              link:
                "https://techcrunch.com/2011/04/13/getglue-passes-1m-users-100m-data-points/"
            },
            {
              quote: [
                "Social Entertainment App GetGlue Streamlines iPhone App Around Check-Ins And Tweets"
              ],
              image: "TechCrunch_2013.svg",
              name: "TechCrunch",
              company: "September 20, 2011",
              link:
                "https://techcrunch.com/2011/09/20/social-entertainment-app-getglue-streamlines-iphone-app-around-check-ins-and-tweets/"
            },
            {
              quote: [
                "GetGlue Embraces Its Inner Foursquare With New iPhone App"
              ],
              image: "mashable.jpg",
              name: "Mashable",
              company: "September 20, 2011",
              link:
                "https://mashable.com/2011/09/20/getglue-iphone-refresh/#UzKGE4XoSqqj"
            },
            {
              quote: [
                "Social Entertainment Network GetGlue Raises $12 Million In New Financing"
              ],
              image: "TechCrunch_2013.svg",
              name: "TechCrunch",
              company: "January 1, 2012",
              link:
                "https://techcrunch.com/2012/01/11/social-entertainment-network-getglue-raises-12-million-in-new-financing/"
            },
            {
              quote: [
                "GetGlue Hits 2 Million Users and Rolls Out New Guide Page"
              ],
              image: "mashable.jpg",
              name: "Mashable",
              company: "January 11, 2012",
              link:
                "https://mashable.com/2012/01/11/getglue-2-million-users/#b8oVhLBKhEq3"
            },
            {
              quote: [
                "With 3 Million Users, GetGlue Goes Big With A New Social TV App Built Just For The iPad"
              ],
              image: "TechCrunch_2013.svg",
              name: "TechCrunch",
              company: "August 16, 2012",
              link:
                "https://techcrunch.com/2012/08/16/with-3-million-users-getglue-goes-big-with-a-new-social-tv-app-built-just-for-the-ipad/"
            },
            {
              quote: [
                "GetGlue Redesigns Site to Be a Personalized Viewing Guide"
              ],
              image: "mashable.jpg",
              name: "Mashable",
              company: "September 24, 2012",
              link:
                "https://mashable.com/2012/09/24/getglue-redesign-website-app/#W7jxtqOmbZq3"
            }
          ]
        }
      ]
    }
  ]
};

export default projectData;
