import React, { Component } from "react";
import "./style.scss";
import config from "../App/mixpanel-events";
import projectData from "../Projects/projects.js";
import { NavLink } from "react-router-dom";
import uniqueId from "react-html-id";
import mixpanel from "mixpanel-browser";

class ProjectList extends Component {
  constructor(props) {
    super(props);

    uniqueId.enableUniqueIds(this);
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
  }

  componentDidMount() {
    mixpanel.track(config.mixpanelEvents.Projects);
  }

  projectCard = project => {
    let imgUrl = `/${process.env.PUBLIC_URL}img/projects/${project.url}/${
      project.thumbnail
    }`;

    return (
      <NavLink exact to={`/project/${project.url}`} key={this.nextUniqueId()}>
        <div className="project-card">
          <img
            src={imgUrl}
            alt={`thumbnail of ${project.title}`}
            style={{
              width: "344px",
              height: "194px",
              borderRadius: "4px 4px 0 0"
            }}
          />
          <div
            style={{
              height: "56px",
              width: "100%",
              color: "transparentize($charleston-green, .13)",
              fontSize: "1em",
              fontWeight: "500",
              padding: "16px"
            }}
          >
            {project.title}
          </div>
        </div>
      </NavLink>
    );
  };

  list = () => {
    let cards = projectData.projects.map(element => {
      return this.projectCard(element);
    });

    return <div style={{ paddingTop: "56px" }}>{cards}</div>;
  };

  render() {
    return (
      <div className="panel-right">
        <div className="ProjectList">
          <h1>Projects</h1>
          <p>
            Here is a more recent sampling of some projects that I have worked
            on. Design tools like InVision, music players and digital education
            tools.
          </p>
          {this.list()}
          {window.scroll(0, 0)}
        </div>
      </div>
    );
  }
}

export default ProjectList;
