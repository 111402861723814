import React from "react";
import "./style.scss";

const GenericInfo = props => {
  return (
    <div className="c-generic-info">
      <a href={props.link}>
        <div className="c-generic-info__image-container">
          <img
            src={`/${process.env.PUBLIC_URL}img/projects/${props.image}`}
            alt={props.alt}
          />
        </div>
        <span>{props.children}</span>
      </a>
    </div>
  );
};

export default GenericInfo;
