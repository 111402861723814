import React, { Component } from "react";
import "./style.scss";
import nextdayjs from "nextday-js";
import dateFormat from "dateformat";

class AssessmentDate extends Component {
  render() {
    var nextDate = nextdayjs.next(new Date(), 2);
    //var dateString = (nextDate.getMonth() + 1) + "/" + nextDate.getDate() + "/" + nextDate.getFullYear();
    var dateString = dateFormat(nextDate, "mmmm dS");

    return (
      <span className={`assess ${this.props.dateColor}`}>{dateString}</span>
    );
  }
}

export default AssessmentDate;
