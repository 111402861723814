import React, { Component } from "react";
import "./style.scss";
import ProductHunt from "../ProductHunt";
import GenericInfo from "../GenericInfo";
import uniqueId from "react-html-id";
//import bulletImage from "./bullet.svg";

class SideInfo extends Component {
  constructor(props) {
    super(props);

    uniqueId.enableUniqueIds(this);
  }

  description = () => {
    const markup = this.props.Content.description.map(element => {
      return (
        <section className="c-project-description" key={this.nextUniqueId()}>
          {element.text}
          {Object.keys(element).indexOf("bullets") > 0 &&
            this.bullets(element.bullets)}
        </section>
      );
    });
    return markup;
  };

  bullets = elements => {
    const markup = elements.map(element => {
      return <li key={this.nextUniqueId()}>{element}</li>;
    });
    return <ul>{markup}</ul>;
  };

  quote = content => {
    const markup = content.quote.map(element => {
      return (
        <span className="c-citation__quote" key={this.nextUniqueId()}>
          {element}
        </span>
      );
    });
    return markup;
  };

  citation = () => {
    const markup = this.props.Content.testimonial.map(element => {
      return (
        <div className="c-citation-container">
          <p className="c-citation">
            <span className="c-citation__image-container">
              <a href={element.link}>
                <img
                  className="c-citation__image"
                  src={`/${process.env.PUBLIC_URL}img/projects/${
                    this.props.Project.url
                  }/${element.image}`}
                  alt="Avatar"
                />
              </a>
            </span>
            <a href={element.link} className="c-citation__person">
              {element.name}
            </a>
            <span className="c-citation__company">{element.company}</span>
            {this.quote(element)}
          </p>
          <div className="c-citation__border" />
        </div>
      );
    });
    return markup;
  };

  productHunt = () => {
    return (
      <ProductHunt link={this.props.Content.productHunt.link}>
        Featured on Product Hunt in {this.props.Content.productHunt.text}
      </ProductHunt>
    );
  };

  genericInfo = () => {
    return (
      <GenericInfo
        image={`${this.props.Project.url}/${
          this.props.Content.genericInfo.image
        }`}
        alt=""
        topMargin="16px"
        link={this.props.Content.genericInfo.link}
      >
        Featured in {this.props.Content.genericInfo.source}{" "}
        {this.props.Content.genericInfo.date}.
      </GenericInfo>
    );
  };

  render() {
    return (
      <section className="c-section-left">
        <h1>{this.props.Content.title}</h1>
        <h3 className="p-16">{this.props.Content.subtitle}</h3>
        <div className="c-section-left__year">{this.props.Content.year}</div>
        {Object.keys(this.props.Content).indexOf("testimonial") > 0 &&
          this.citation()}
        {Object.keys(this.props.Content).indexOf("productHunt") > 0 &&
          this.productHunt()}
        {Object.keys(this.props.Content).indexOf("genericInfo") > 0 &&
          this.genericInfo()}
        <div
          style={{
            height: "32px"
          }}
        />
        {Object.keys(this.props.Content).indexOf("description") > 0 &&
          this.description()}
      </section>
    );
  }
}

export default SideInfo;
