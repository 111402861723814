import React, { Component } from "react";
import "./style.css";

class SideImage extends Component {
  render() {
    let imgUrl = `/${process.env.PUBLIC_URL}img/projects/${
      this.props.Project.url
    }/${this.props.Content.image}`;

    return (
      <div className="side-image-section-right">
        <section>
          <a href={imgUrl}>
            <img
              src={imgUrl}
              alt={this.props.Content.title}
              className="overflow-image"
            />
          </a>
        </section>
      </div>
    );
  }
}

export default SideImage;
