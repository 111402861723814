import React, { Component } from "react";
import "./style.scss";
import AssessmentButton from "../AssessmentButton";
import AssessmentDate from "../AssessmentDate";
import { NavLink } from "react-router-dom";
import config from "../App/mixpanel-events";
import mixpanel from "mixpanel-browser";

class HomeContent extends Component {
  constructor(props) {
    super(props);

    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
  }

  componentDidMount() {
    mixpanel.track(config.mixpanelEvents.Home);
  }

  render() {
    return (
      <div className="panel-right">
        <div className="HomeContent">
          <h1>
            Design. Teams. Strategy.
            <br />
            <span className="highlight">All things product.</span>
          </h1>
          <p className="home-intro">
            New York Code Company is the digital product and user experience
            design shop founded in Brooklyn, NY by{" "}
            <a href="https://christopherandersson.com">Christopher Andersson</a>
            . I have worked with products like SiriusXM, InVision, Myspace and
            Yahoo!.
          </p>
          <p>I work remotely around the world, and locally in New York City.</p>
          <p>
            Have a look at <NavLink to="/projects">my work</NavLink>, and if you
            like what you see get in touch.
          </p>
          <div className="home-call-button">
            <AssessmentButton />
            <small>
              15 minute intro call available on <AssessmentDate />.
            </small>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeContent;
