import React, { Component } from "react";
import "./style.scss";
import GenericInfo from "../GenericInfo";

class SideMobile extends Component {
  render() {
    let imgUrl = `/${process.env.PUBLIC_URL}img/projects/${
      this.props.Project.url
    }/${this.props.Content.image}`;

    let videoUrl = `/${process.env.PUBLIC_URL}img/projects/${
      this.props.Project.url
    }/${this.props.Content.videoSource}`;

    let section = () => {
      if (this.props.Content.proto === "true") {
        return (
          <section>
            <div className="c-prototype">
              <iframe
                width="438"
                height="930"
                src={this.props.Content.protoSource}
                frameborder="0"
                title="prototype"
                allowfullscreen
              />
            </div>
            <div className="c-prototype-placeholder">
              <GenericInfo
                link={this.props.Content.protoSource}
                image={`${this.props.Project.url}/${
                  this.props.Content.protoAltImage
                }`}
                alt={this.props.Content.title}
              >
                Live prototype
              </GenericInfo>
            </div>
          </section>
        );
      }

      if (this.props.Content.video === "true") {
        return (
          <section>
            <video
              controls
              style={{
                width: "100%",
                objectFit: "scale-down",
                borderRadius: "4px"
              }}
            >
              <source src={videoUrl} type="video/mp4" />
            </video>
          </section>
        );
      }

      return (
        <section>
          <a href={imgUrl}>
            <img
              src={imgUrl}
              alt={this.props.Project.title}
              className="mobileImg"
            />
          </a>
        </section>
      );
    };

    return <div className="section-right">{section()}</div>;
  }
}

export default SideMobile;
