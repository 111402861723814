import React, { Component } from "react";
import "./style.scss";
import { Route, NavLink } from "react-router-dom";
import { push as Menu } from "react-burger-menu";
import NavPanel from "../NavPanel";
import HomeContent from "../HomeContent";
import ServicesContent from "../ServicesContent";
import ProjectList from "../ProjectList";

class SplitView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      menuIsOpen: false,
      splitScreen: true
    };

    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
  }

  handleClick() {
    if (this.state.open) {
      this.setState({ open: false });
    } else {
      this.setState({ open: true });
    }
  }

  handleMenuItemClick(menuItem) {
    this.setState({ menuIsOpen: false });
  }

  render() {
    return (
      <div className="appSplit">
        <Menu
          isOpen={this.state.menuIsOpen}
          width={"100%"}
          className={"nav-menu"}
          pageWrapId={"main-page"}
          outerContainerId={"main-app"}
          right
        >
          <li>
            <NavLink
              exact
              to="/"
              activeClassName="active-nav"
              className="menu-nav"
              onClick={this.handleMenuItemClick}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/services"
              activeClassName="active-nav"
              className="menu-nav"
              onClick={this.handleMenuItemClick}
            >
              Services
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/projects"
              activeClassName="active-nav"
              className="menu-nav"
              onClick={this.handleMenuItemClick}
            >
              Projects
            </NavLink>
          </li>
        </Menu>
        <div className="pageSplit" id="main-page">
          <Route exact path="/" component={NavPanel} />
          <Route exact path="/" component={HomeContent} />
          <Route path="/services" component={NavPanel} />
          <Route path="/services" component={ServicesContent} />
          <Route exact path="/projects" component={NavPanel} />
          <Route exact path="/projects" component={ProjectList} />
        </div>
      </div>
    );
  }
}

export default SplitView;
